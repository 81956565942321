enum BrowserName {
  firefox = 'Firefox',
  samsung = 'Samsung Browser',
  opera = 'Opera',
  explorer = 'Internet Explorer',
  edgeold = 'Edge (Old)',
  edge = 'Edge',
  chromium = 'Chrome',
  safari = 'Safari',
  mobilesafari = 'Mobile Safari',
  unknown = 'Unknown'
}

export const getBrowserName = (): BrowserName => {
  const userAgent = navigator.userAgent

  const isMobileSafari =
    userAgent.includes('Safari') &&
    userAgent.includes('Mobile') &&
    (userAgent.includes('iPhone') || userAgent.includes('iPad') || userAgent.includes('iPod'))

  if (isMobileSafari) {
    return BrowserName.mobilesafari
  }

  const browsers = [
    { search: 'Firefox', name: BrowserName.firefox },
    { search: 'SamsungBrowser', name: BrowserName.samsung },
    { search: 'Opera', name: BrowserName.opera },
    { search: 'OPR', name: BrowserName.opera },
    { search: 'Trident', name: BrowserName.explorer },
    { search: 'Edge', name: BrowserName.edgeold },
    { search: 'Edg', name: BrowserName.edge },
    { search: 'Chrome', name: BrowserName.chromium },
    { search: 'Safari', name: BrowserName.safari }
  ]

  const browser = browsers.find(({ search }) => userAgent.includes(search)) ?? { name: BrowserName.unknown }

  return browser.name
}

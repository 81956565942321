
import Vue from 'vue'

export default Vue.extend({
  name: 'KycFaq',
  computed: {
    accordions () {
      return [
        {
          title: this.$t('faq.whatIsKyc.title'),
          content: this.$t('faq.whatIsKyc.content')
        },
        {
          title: this.$t('faq.ifIDontPass.title'),
          content: this.$t('faq.ifIDontPass.content')
        },
        {
          title: this.$t('faq.docsToId.title'),
          content: this.$t('faq.docsToId.content')
        },
        {
          title: this.$t('faq.howLongVerification.title'),
          content: this.$t('faq.howLongVerification.content')
        },
        {
          title: this.$t('faq.docsToIdAddress.title'),
          content: this.$t('faq.docsToIdAddress.content')
        },
        {
          title: this.$t('faq.whyCantConfirm.title'),
          content: this.$t('faq.whyCantConfirm.content')
        },
        {
          title: this.$t('faq.whyYouNeedId.title'),
          content: this.$t('faq.whyYouNeedId.content')
        },
        {
          title: this.$t('faq.howYouHandleData.title'),
          content: this.$t('faq.howYouHandleData.content', { host: window.location.host })
        },

        {
          title: this.$t('faq.howDoYouHandleData.title'),
          content: this.$t('faq.howDoYouHandleData.content')
        },
        {
          title: this.$t('faq.doYouPassData.title'),
          content: this.$t('faq.doYouPassData.content')
        },
        {
          title: this.$t('faq.resetTerms.title'),
          content: this.$t('faq.resetTerms.content')
        },
        {
          title: this.$t('faq.yubikey.title'),
          content: this.$t('faq.yubikey.content')
        }
      ]
    }
  },
  methods: {
    goBack () {
      if (document.referrer) {
        window.location.href = document.referrer
      } else {
        this.$router.push('/id')
      }
    }
  }
})


import Vue from 'vue'
import moment from 'moment'
import jwtDecode from 'jwt-decode'
import SERVICES_CONSTANTS, { StepByStepGlossary } from '@/constants/services'
import YubikeyAuthMixin from '@/mixins/YubikeyAuthMixin'
import { getBrowserName } from '@/utils/common'

enum AuthMethods {
  sms = 'sms',
  ga = 'ga',
  yubikey = 'yubikey'
}

enum ErrorStatuses {
  tokenIsDead = 404,
  invalidPayloadData = 400,
  wrongService = 418
}

export default Vue.extend({
  name: 'loginSecondFactor',
  mixins: [YubikeyAuthMixin],
  data () {
    return {
      isKycZero: false,
      sessionId: '',
      letRetry: false,
      yubikeyErrors: [],
      smsForm: {
        fields: {
          smsCode: {
            valid: false,
            codeIsSent: false,
            codeSentBefore: false,
            retryTimeout: 60,
            retries: 3,
            letRetry: false,
            value: '',
            rules: [
              (v: string) => Boolean(v) || this.$t('common.errors.required'),
              (v: string) => v.length === SERVICES_CONSTANTS.CODE_LENGTH || this.$t('common.errors.codeMustConsist', { length: SERVICES_CONSTANTS.CODE_LENGTH })
            ],
            errorsBucket: []
          }
        }
      },
      gaForm: {
        fields: {
          GACode: {
            valid: false,
            value: '',
            rules: [
              (v: string) => Boolean(v) || this.$t('common.errors.required'),
              (v: string) => v.length === SERVICES_CONSTANTS.CODE_LENGTH || this.$t('common.errors.codeMustConsist', { length: SERVICES_CONSTANTS.CODE_LENGTH })
            ],
            errorsBucket: []
          }
        }
      },
      isLoading: false
    }
  },
  computed: {
    getAuthMethod (): string {
      const vm = this as any
      const hasMethod = Object.values(AuthMethods).includes(vm.$route.params.method)
      return hasMethod ? vm.$route.params.method : AuthMethods.sms
    },
    servicesConst () {
      return SERVICES_CONSTANTS
    },
    isMobileSafari (): boolean {
      const browser = getBrowserName()
      return browser === 'Mobile Safari'
    }
  },
  mounted (): void {
    const vm = this as any
    const isYubiKeyAuth = vm.getAuthMethod === 'yubikey'
    if (!isYubiKeyAuth) return
    vm.startTimer()
    vm.yubiKeyAuthInit()
  },
  watch: {
    'smsForm.fields.smsCode.retryTimeout' (v) {
      const vm = this as any
      if (v > 0) {
        setTimeout(() => {
          vm.smsForm.fields.smsCode.retryTimeout = v - 1
        }, 1000)
      } else vm.smsForm.fields.smsCode.letRetry = true
    },
    'smsForm.fields.smsCode.valid' (v) {
      if (this.isMobileSafari) return
      if (v && this.smsForm.fields.smsCode.value.length > 0) this.handleSubmit()
    },
    'gaForm.fields.GACode.valid' (v) {
      if (v && this.gaForm.fields.GACode.value.length > 0) this.handleSubmit()
    },
    'smsForm.fields.smsCode.value' () {
      const vm = this as any
      vm.smsForm.fields.smsCode.errorsBucket = []
    },
    'gaForm.fields.GACode.value' () {
      const vm = this as any
      vm.gaForm.fields.GACode.errorsBucket = []
    }
  },
  methods: {
    async hasntAccessHandler () {
      this.$store.commit('popupModule/setClosed')
      this.$router.push({ name: 'AccessRepair', query: { secondFactor: this.getAuthMethod, an3K8QkkJb3Mzz: this.sessionId } })
    },
    yubiKeyAuthInit () {
      const vm = this as any
      vm.yubikeyErrors = []
      vm.YubikeyAuth(StepByStepGlossary.login, null, async (authResponse: any, sessionId: string) => {
        if (!authResponse.success) return vm.setServerError(authResponse.data.errors.join(''))
        const loginResponse = await vm.$services.authorization.signin.doSignIn({
          session_id: sessionId
        })
        if (loginResponse.success) {
          vm.handleSuccessLogin(loginResponse.data)
          return
        }
        if (loginResponse.status === ErrorStatuses.tokenIsDead) {
          vm.$router.replace({ name: 'SignIn' })
        } else if (loginResponse.status === ErrorStatuses.wrongService) {
          vm.$router.replace({ name: 'SignIn', query: { errors: 'wrongService' } })
        }

        vm.setServerError(loginResponse.data.errors)
      }, this.sessionId, vm.yubiKeyAuthInit)
    },
    setServerError (errors: any) {
      const vm = this as any
      vm.yubikeyErrors.push(errors)
      vm.letRetry = true
    },
    startTimer (): void {
      const vm = this as any
      vm.letRetry = false
      setTimeout(() => {
        vm.letRetry = true
      }, 30000)
    },
    async sendSMS (): Promise<void> {
      const vm = this as any
      vm.smsForm.fields.smsCode.loading = true
      vm.smsForm.fields.smsCode.codeSentBefore = false
      const response = await vm.$services.authorization.signin.sendSMSToLogin({ sessionId: this.sessionId })
      if (response.success) {
        vm.smsForm.fields.smsCode.loading = false
        vm.smsForm.fields.smsCode.codeIsSent = true
        vm.smsForm.fields.smsCode.letRetry = false
        vm.smsForm.fields.smsCode.retryTimeout = moment(response.data.work_until).diff(moment(), 'seconds')
        vm.smsForm.fields.smsCode.retries -= 1
        setTimeout(() => {
          vm.smsForm.fields.smsCode.retryTimeout -= 1
        }, 1000)
      } else {
        vm.smsForm.fields.smsCode.loading = false
        if (response?.data?.work_until) {
          vm.smsForm.fields.smsCode.codeSentBefore = true
          vm.smsForm.fields.smsCode.codeIsSent = true
          vm.smsForm.fields.smsCode.letRetry = false
          vm.smsForm.fields.smsCode.retryTimeout = moment(response.data.work_until).diff(moment(), 'seconds')
          vm.smsForm.fields.smsCode.retries -= 1
          setTimeout(() => {
            vm.smsForm.fields.smsCode.retryTimeout -= 1
          }, 1000)
        } else {
          vm.smsForm.fields.smsCode.codeSendError = true
          vm.smsForm.fields.smsCode.letRetry = true
        }
      }
    },
    handleSuccessLogin (responseData: { [key: string]: string }): void {
      const vm = this as any
      const location = window.location
      const host = location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0]
      vm.$cookies.set('jwtr', responseData.refresh_token, {
        expires: moment.unix((jwtDecode(responseData.refresh_token) as any).exp).toDate(),
        domain: '.' + host
      })

      if (this.$route.params.clientToken) {
        this.$store.dispatch('user/loadUser')
        this.$router.replace({ name: 'authentication', query: { clientToken: this.$route.params.clientToken } })
      } else {
        vm.$store.commit('setAppIsRedirecting', true)
        setInterval(() => {
          if (vm.form.successTimer > 0) vm.form.successTimer--
        }, 1000)
        const acceptor = localStorage.getItem('acceptor')
        window.location.href = (acceptor || SERVICES_CONSTANTS.ACCEPTOR_BASE_URL)
      }
    },
    async handleSubmit (): Promise<void> {
      const vm = this as any
      vm.isLoading = true
      vm.smsForm.fields.smsCode.errorsBucket = []
      vm.gaForm.fields.GACode.errorsBucket = []
      const method = vm.$route.params.method

      const payload: { sessionId: string, codeSms?: string, codeGa?: string } = {
        sessionId: this.sessionId
      }
      if (method === 'sms') {
        payload.codeSms = vm.smsForm.fields.smsCode.value
      } else payload.codeGa = vm.gaForm.fields.GACode.value

      const validateSessionResponse = await vm.$services.authorization.user.validateSession({
        sessionId: payload.sessionId,
        payload: {
          sms: payload.codeSms,
          ga: payload.codeGa
        }
      })
      if (validateSessionResponse.status === ErrorStatuses.invalidPayloadData) {
        if (method === 'sms') {
          vm.smsForm.fields.smsCode.errorsBucket = validateSessionResponse.data.errors
        } else {
          vm.gaForm.fields.GACode.errorsBucket = validateSessionResponse.data.errors
        }
      }

      const response = await vm.$services.authorization.signin.doSignIn({
        session_id: payload.sessionId
      })
      if (response.success) {
        vm.handleSuccessLogin(response.data)
      } else {
        if (response.status === ErrorStatuses.tokenIsDead) {
          vm.$router.replace({ name: 'SignIn' })
        } else if (response.status === ErrorStatuses.invalidPayloadData) {
          if (method === 'sms') {
            vm.smsForm.fields.smsCode.errorsBucket = response.data.errors
          } else {
            vm.gaForm.fields.GACode.errorsBucket = response.data.errors
          }
        } else if (response.status === ErrorStatuses.wrongService) {
          vm.$router.replace({ name: 'SignIn', query: { errors: 'wrongService' } })
        }
      }
      vm.isLoading = false
    }
  },
  async created (): Promise<void> {
    this.sessionId = this.$route.query.an3K8QkkJb3Mzz
    if (!this.sessionId) this.$router.replace({ name: 'SignIn' })
    const method = this.$route.params.method
    if (method === AuthMethods.sms) this.sendSMS()
  }
})
